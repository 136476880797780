<template>
    <v-alert
        border="top"
        color="accent"
        dark
        icon="mdi-file-document-edit"
        prominent
        >{{
            $t("applications.cosigner-alert-message", {
                property: application.property.name,
            })
        }}</v-alert
    >
</template>

<script>
export default {
    components: {},
    props: {
        application: {
            type: Object,
            required: true,
        },
    },
    computed: {},
    data() {
        return {};
    },
    methods: {},
    mounted() {
        this.$emit("update", { complete: true });
    },
};
</script>

<style scoped>
</style>