var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    {
      attrs: {
        border: "top",
        color: "accent",
        dark: "",
        icon: "mdi-file-document-edit",
        prominent: ""
      }
    },
    [
      _vm._v(
        _vm._s(
          _vm.$t("applications.cosigner-alert-message", {
            property: _vm.application.property.name
          })
        )
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }